@import 'mixin/fonts';
.trading {
  background: #01050D;
  height: 875px;
  .trading-in{
    max-width: 1230px;
    width: 100%;
    margin: 0 auto;

    .trading-left{
      float: left;
      padding-top: 53px;
      max-width: 600px;
      width: 100%;
      h2{
        @include font(900, 36px);
        line-height: 62px;
        color: $white;
        padding-top: 100px;
      }
      p{
       color: $white;
       @include font(200, 14px);
       line-height: 26px;
       max-width: 470px;
       width: 100%;
       padding-top: 53px;
      }
      .trading-btn{
        @extend %header-btn;
        background-color: $blue;
        margin-top: 70px;
        color: $white;
        width: 200px;
        @include font(600, 18px);
      }
    }
    .trading-right{
      margin-top: 157px;
      float: right;
      background: url(../images/img2.png) no-repeat 0 0;
      width: 625px;
      height: 597px;
    }
  }
}