@import 'mixin/fonts';
.table-results-main {
    background-color: #01050D;
   p {
    color: $white;
    text-align: center;
    @include font(300, 14px);
    line-height: 26px;
    max-width: 1040px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    top: -123px;
  }
  .table-results{
    background-color: $white;
    max-width: 1220px;
    width: 100%;
    margin: 0 auto;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    height: auto;
    padding-bottom: 10px;
    position: relative;
    top: -195px;
    .table-results-header{
      width: 95%;
      margin: 0 auto;
      padding-top: 30px;
      .table-results-tr{
        width: 100%;
        padding: 15px 0;
        .table-results-th{
          display: inline-block;
          width: 12.1%;
          @include font(normal, 12px);
          color: $blue2;
          &:last-of-type{
            text-align: right;
          }
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7){
            text-align: right;
          }
        }
        .table-results-td{
          display: inline-block;
          width: 12.1%;
          @include font(normal, 13px);
          color: $black2;
          &:last-of-type{
            text-align: right;
          }
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7){
            text-align: right;
          }
         }
         .sell,
         .buy{
          &:before{
           display: block;
           width: 8px;
           height: 8px;
           background-color: #E55541;
           float: left;
           content: "";
           border-radius: 10px;
           margin: 5px 7px 0 0;
         }
        }

        .buy{
          &:before{
            background-color: #00D983;
           }
          }
      }
      .line-table{
        border: none;
        border-bottom: 1px solid #D8D8D8;
        width: 100%;
      }
    }
  }
}

.fon{
  display: none;
}

.table-results-main{
  .block {
    position: relative;
    z-index: 3;
  }
  .table {
    margin-top: 0px;
    padding: 51px 27px 27px;
    border-radius: 5px;
    background-color: #fff;
  }
  
  .table .tc {
    font-size: 13px;
    line-height: 1.1;
    color: #1f2229;
  }
  
  .table .tc:nth-child(1) {
    width: 5%;
  }
  
  .table .tc:nth-child(2) {
    width: 7%;
  }
  
  .table .tc:nth-child(3) {
    width: 7%;
  }
  
  .table .tc:nth-child(4) {
    width: 7%;
  }
  
  .table .tc:nth-child(5) {
    width: 10%;
  }
  
  .table .tc:nth-child(6) {
    width: 7%;
  }
  
  .table .tc:nth-child(7) {
    width: 10%;
  }
  
  .table .tc:nth-child(8) {
    width: 8%;
  }
  
  .table .tc:nth-child(9) {
    width: 10%;
  }
  
  .table .tc:nth-child(10) {
    width: 13%;
    text-align: right;
  }
  
  .thead {
    display: flex;
    justify-content: space-between;
    padding: 0 6px;
    padding-bottom: 18px;
    border-bottom: 1px solid #e8e9ea;
  }
  
  .thead .tc {
    font-size: 12px;
    line-height: 16px;
    color: #9aa5b8;
  }
  
  .tbody .tc:last-child {
    text-align: right;
  }
  
  .tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 16px 6px;
    border-bottom: 1px solid #e8e9ea;
  }
  
  .loadmore {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  
  .load {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1px;
    color: #9aa5b8;
    text-decoration: none;
  }
  
  .signal {
    padding-left: 20px;
    position: relative;
  }
  .signal {
    &.green{
      &:after{
        background-color: #00D983;
      }
    }
    &:after{
      z-index: 1;
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 8px;
      height: 8px;
      border-radius: 8px;
      background-color: #e55541;
      top: 50%;
      margin-top: -4px;
    }
  }
}

.loader-block{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}
.lds-ring-loader {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  opacity: 0.5;
}
.lds-ring-loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 6px;
  border: 3px solid #2B63E3;
  border-radius: 50%;
  animation: lds-ring-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #2B63E3 transparent transparent transparent;
}
.lds-ring-loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring-loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring-loader div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
