@media (max-width: 1300px){
.header {
  .header-in{
   max-width: 1000px;
   .header-top{
    .header-middle{
     .header-left{
       max-width: 500px;
      h1{
       font-size: 40px;
     }
    }
    .header-right{
      .fancybox{
        width: 400px;
        top: 218px;
        left: -369px;
        &:before{
          top: 56px;
          left: 146px;
       }
      }
      .header-content{
        max-width: 300px;
        margin: 50px 0 0 50px;
        .item{
          h2{
          font-size: 27px;
        }
       }
      }
     } 
    }
   } 
  }
 }
}



@media (max-width: 1030px){
.header {
 .header-in{
    max-width: 800px;
  .header-top{
    .header-middle{
     .header-left{
       max-width: 325px;
      h1{
        font-size: 25px;
        line-height: 43px;
      }
      .social-btn{
        margin-top: 250px;
      }
     }
     .header-right{
       max-width: 45%;
       .fancybox{
         width: 300px;
         left: -300px;
         top: 300px;
         &:before{
          top: 80px;
          left: 50%;
         }
       }
       .header-content{
         margin: 50px 0 0 27px;
         max-width: 270px;
        .item{
          p{
            width: 180px;
            line-height: 20px;
            margin: 16px 0 0 30px;
          }
          h2{
            font-size: 20px;
          }
          span{
            margin: -3px 10px 0 0;
          }
        }
       }
     }
    } 
   }
   .top-menu{

    li{
      margin: 0 0 0 10px;
    }
   }
  }
 }
}

@media (max-width: 830px){
.header {
 .header-in{
   max-width: 600px;
  .top-menu{
    li{

      a{
       font-size: 13px;
     }
    }
   }
  }
 }
}




@media (max-width: 670px){
.header {
  height: 1250px;
 .header-in{
  .header-top{
    position: relative;
    height: 1250px;
    .logo{
      position: relative;
      z-index: 3;
    }
    .mobil-button{
      position: absolute;
      right: 7px;
      top: 40px;
      display: block;
      outline: none;
      z-index: 3;
    }
    .header-middle{
      .header-left{
        h1{
          text-align: center;
        }
        .header-btn{
          float: none;
          margin: 50px auto 0;
        }
        .social-btn{
          margin: 810px auto 0  ;
          max-width: 265px;
          li{
            &:last-of-type{
              margin: 0;
            }
          }
        }
      }
      .header-right{
        max-width: 100%;
        top: 600px;
        height: 550px;
        .fancybox{
          left: 0;
          top: -147px;
        }
        .header-content{
          margin: 70px 0 0 15px;
          .item{
            p{
              width: 240px;
              font-size: 15px;
            }
          }
        }
      }
    }
   }
   max-width: 300px;

    .top-menu{
      display: none;
      background: $white;
      margin: 6px;
      width: 200px;
      box-shadow: 0 0 10px $white;
      padding: 20px 0;
      position: absolute;
      left: 87px;
      top: 62px;
      z-index: 1000;
     li{
      display: block;
      width: 100%;
      overflow: hidden;
      margin: 0 0 10px 0;
      a{
       font-size: 15px;
       color: $black;
       text-align: center;
       display: block;
       width: 100%;
       float: none;
     }
    }
   }
  }
 }
}