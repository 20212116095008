@import 'mixin/fonts';
.wallets {
  background-color: #01050D; 
  padding-bottom: 100px;
  .wallets-in {
   max-width: 1030px;
   width: 100%;
   margin: 0 auto;
   h2 {
    @include font(900, 48px);
    line-height: 62px;
    color: $white;
    padding: 131px 0 70px;
    text-align: center;
  }
  .wallets-content {
    display: table-cell;
    width: 50%;
    .wallets-btn {
      float: left;
      border: 1px solid $white;
      @include font(bold, 14px);
      padding-top: 20px;
      @extend %header-btn;
      color: $white;
      width: 200px;
      height: 60px;
      &:before{
      content: url(../images/icon.svg);
      width: 23px;
      height: 23px;
      display: block;
      float: left;
      padding: 0 10px 0 0;
      position: relative;
      left: 31px;
      top: -1px;   
    }
   }
   .mac{
    &:before{
      content: url(../images/icon2.svg);
      width: 23px;
      height: 23px;
      display: block;
      float: left;
      padding: 0 10px 0 0;
      position: relative;
      top: -4px;
      left: 57px;
    }
   }

   .linux{
    &:before{
      content: url(../images/icon3.svg);
      width: 23px;
      height: 23px;
      display: block;
      float: left;
      padding: 0 10px 0 0;
      position: relative;
      top: -2px;
      left: 57px;
    }
   }
  }
 }
}
