@import 'mixin/fonts';
.exchanges {
   background-color: #01050D;
   padding-bottom: 120px;
   .exchanges-in{
    max-width: 1230px;
    width: 100%;
    margin: 0 auto;
    h2{
      @include font(900, 48px);
      color: $white;
      line-height: 62px;
      text-align: center;
      padding: 57px 0 57px;
    }
    .exchanges-wrapper{
      display: flex;
      justify-content: space-between;
      padding: 0 60px;
    }
    .exchanges-left{
      margin-right: 20px;
    }
    .exchanges-left,
    .exchanges-right{
      float: left;
      width: 100%;
      padding-top: 67px;
      display: flex;
      border-radius: 5px;
      padding-bottom: 63px;
      align-items: center;
      flex-direction: column;
      .buy-btn,
      .buy-btn{
        @extend %header-btn;
        @include font(bold, 18px);
        background-color: $white;
        color: $blue;
        margin: 45px auto 0;
        width: 200px;
        height: 60px;
      }
    }

    .exchanges-right{
        float: right;
      }
   }
}
