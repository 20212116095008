@import 'mixin/fonts';
.header {
  background: #01050D;
  height: 820px;
  
  .header-in {
    max-width: 1230px;
    width: 100%;
    margin: 0 auto;

    .header-top {
      overflow: visible;
      width: 100%;
      float: left;
      .mobil-button{
        width: 24px;
        display: block;
        border: none;
        background: none;
        cursor: pointer;
        span{
         display: block;
         background-color: $white;
         width: 24px;
         height: 2px;
         margin-bottom: 6px;
        }
      }
      .mobil-button{
        display: none;
      }
      .header-middle{
        width: 100%;
        margin-top: 190px;

        .header-left{
            max-width: 670px;
            width: 100%;
            float: left;
            position: relative;
            &:after{
              display: block;
              content: '';
              position: absolute;
              left: -450px;
              top: -400px;
              width: 1026px;
              height: 1026px;
              background-image: url(../images/firstScreenDecoration.svg);
              background-repeat: no-repeat;
              background-position: center;
              z-index: 1;
            }
            *{
              position: relative;
              z-index: 2;
            }
          h1{
            color: #ffffff;
            @include font(900, 50px);
            line-height: 72px;
            position: relative;
          }
          .header-btn {
            @include font(700, 18px);
            line-height: 26px;
            background: #2B63E3;
            padding-top: 17px;
            margin-top: 70px;
            @extend %header-btn;
            color: $white;
            width: 200px;
            height: 60px;
          }
          .social-btn{
            margin-top: 167px;
            li{
              display: inline-block;
              margin: 0 16px 0 0;
            }
          }
        }

        .header-right{
          background-color: $blue;
          max-width: 41%;
          height: 590px;
          width: 100%;
          float: right;
          position: absolute;
          right: 0;
          top: 199px;
          .header-content{
            max-width: 420px;
            width: 100%;
            margin: 73px 0 0 80px;
            .item{
              display: block;
              overflow: hidden;
              margin-bottom: 51px;
              span{
                color: $white;
                @include font(200, 14px);
								opacity: 0.5;
                margin: 0 22px 0 0;
                position: relative;
                top: 8px;
                width: 20px;
                display: block;
                float: left;
              }
              h2{
                color: $white;
                @include font(900, 36px);
              }
              p{
                color: $white;
                @include font(200, 14px);
                line-height: 26px;
                width: 296px;
                margin: 8px 0 0 45px;
              }
            }
          }

          .fancybox{
            position: absolute;
            bottom: 77px;
            left: -377px;
            width: 415px;
            z-index: 2000;
            &:before{
              width: 101px;
              height: 101px;
              content: url(../images/icon12.png);
              position: absolute;
              top: 50%;
              left: 50%;
              margin-left: -50px;
              margin-top: -50px;
            }
            img{
              max-width: 100%;
              box-shadow: 0px 40px 60px rgba(0, 0, 0, 0.4);
            }
          }
        }
      }
    }

    .logo {
      display: block;
      float: left;
      padding-top: 30px;
    }
    .top-menu {
      float: right;
      padding-top: 37px;
      display: block;

       li {
        display: inline-block;
        margin: 0 0 0 49px;

        a {
          color: $white;
          @include font(200, 16px);
          line-height: 26px;
          :last-of-type {
            margin: 0;
         }
       }          
      }
    }
  }
}
