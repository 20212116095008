@import 'mixin/fonts';
.specification {
  background-color: #01050D;
  overflow: hidden;
  height: auto;
  .specification-in {
    max-width: 1230px;
    width: 100%;
    margin: 0 auto;
    h2 {
     color: $white;
     @include font(900, 50px);
     text-align: center;
     padding: 166px 0 70px;
  }
 }
}


.table-specification {
    float: left;
    max-width: 720px;
    width: 100%;
  .table-specification-header {
     border-bottom: 1px solid $blue3;
     padding-bottom: 15px;
   .table-specification-td {
     display: table-cell;
     color: $blue2;
     @include font(300, 16px);
     line-height: 24px;
     width: 136px;
     padding: 0 0 0 17px;
  }
 }
 .table-specification-hr {
    border-bottom: 1px solid $blue3;
    padding: 7px 0;
		&:nth-child(2n){
			background-color: #0F1929;	
		}
    .table-specification-td{
      display: table-cell;
      width: 136px;
      padding: 0 0 0 21px;
      b {
        color: $white;
        @include font(600, 16px);
     }
    }
  }
}


.table-specification2 {
  float: right;
  max-width: 450px;
  width: 100%;
  margin-top: 39px;
  .table-specification-hr{
    border-top: 1px solid $blue3;
    padding: 0px 0 1px 0;
    display: flex;
		&:nth-child(2n+1){
			background-color: #0F1929;	
		}
    .table-specification-td {
      width: 245px;
      padding: 17.6px 25px;
      &.table-specification-td__left{
        flex-shrink: 0;
      }
      b {
        color: $white;
        @include font(600, 16px);
     }
     &:last-of-type{
     text-align: right;       
   }
  }
  &:last-of-type{
    border-bottom: 1px solid $blue3;
    padding: 3px 0 3px 0;
  }
 }
}
