@media (max-width: 1130px){
.wallets{
  .wallets-in{
    max-width: 900px;
  }
 }
}




@media (max-width: 990px){
.wallets{
  .wallets-in{
    max-width: 700px;
  }
 }
}




@media (max-width: 750px){
.wallets{
  padding-bottom: 30px;
 .wallets-in{
    max-width: 300px;
    h2{
     font-size: 30px;
     padding: 150px 0 30px;
    }
   .wallets-content{
     display: block;
     width: 100%;
     a{
      float: none !important;
      display: block !important;
      margin: 0 auto 25px;
     }
   }
  }
 }
}