@import 'mixin/fonts';

.results {
  background-color: $blue;
  width: 100%;
  height: 540px;

  .results-in{
    max-width: 1230px;
    width: 100%;
    margin: 0 auto;
    h2{
      text-align: center;
      @include font(900, 50px);
      color: #ffffff;
      line-height: 62px;
      padding: 75px 0;
    }
    .net{
      @include font(300, 13px);
      color: $white;
      float: left;
      margin: 16px 80px 0 0;
      span{
        padding: 0 0 0 10px;
      }
    }
    .results-btn{
      @extend %header-btn;
      float: right;
      background-color: $white;
      color: $blue;
      @include font(bold, 18px);
      width: 200px;
      height: 60px;
      margin-top: 8px;
    }
  }
}