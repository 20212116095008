@import 'mixin/fonts';
.development {
 background-color: #01050D;
 padding-top: 107px;
 position: relative;
 overflow: hidden;
 padding-bottom: 50px;
 .development-left {
   background-color: $blue;
   max-width: 35%;
   position: absolute;
   top: -80px;
   left: 0;
   width: 100%;
   z-index: 2;
   &:after{
      display: block;
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: -1000%;
      z-index: 1;
      background-color: $blue;
   }
   h2 {
    @include font(900, 48px);
    line-height: 62px;
    color: $white;
    width: 363px;
    padding: 109px 0 90px;
    margin: 0;
    position: relative;
    z-index: 2;
  }
 }
 .development-in {
   max-width: 1230px;
   width: 100%;
   margin: 0 auto;
   position: relative;
   z-index: 1000;
   &:after{
      display: block;
      position: absolute;
      height: 1px;
      width: 100%;
      left: 0;
      top: 0;
      content: '';
      z-index: 3;
      background-color: #fff;
   }
   .table-development {
    max-width: 730px;
    width: 100%;
    float: right;
    padding-top: 50px;
    .table-development-tr{
        min-height: 48px;
        overflow: hidden;
        width: 100%;
        margin-bottom: 27px;
        display: flex;
     .table-development-td{
        width: 275px;
        display: inline-block;
        color: $white;
        &.table-development-td__month{ width: 41% }
        &.table-development-td__text{ width: 40% }
        &.table-development-td__year{ width: 27% }
      b{
        @include font(600, 18px);
     }
     p{
        @include font(300, 14px);
        line-height: 24px;
     }
     .date{
        text-align: right;
        width: 100%;
        font-weight: 600;
        display: block;
     }
    }
   }
  }
 }
}
