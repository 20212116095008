@media (max-width: 1380px){
.trading{
  .trading-in{
   max-width: 1200px;
   .trading-left{
     max-width: 505px;
     h2{
      line-height: 50px;
      font-size: 35px;
    }
   }
  }
 }
}



@media (max-width: 1240px){
.trading{
 .trading-in{
  max-width: 1000px;
  .trading-left{
    max-width: 405px;
  h2{
     line-height: 50px;
     font-size: 30px;
    }
   }
   .trading-right{
    width: 54%;
    background-size: 100%;
   }
  }
 }
}




@media (max-width: 1060px){
.trading{
    height: 700px;
 .trading-in{
    max-width: 800px;
   .trading-left{
     max-width: 350px;
   h2{
     line-height: 40px;
     font-size: 25px;
     padding-top: 0;
    }
    p{
     padding-top: 40px;
    }
    .trading-btn{
     margin-top: 40px;
    }
   }
   .trading-right{
    width: 50%;
    background-size: 100%;
    padding-top: 70px;
   }
  }
 }
}




@media (max-width: 830px){
.trading{
    height: 550px;
  .trading-in{
      max-width: 600px;
    .trading-left{
     max-width: 250px;
     p{
      padding-top: 20px;
      font-size: 12px;
     }
    h2{
     line-height: 30px;
     font-size: 19px;
     padding-top: 0;
    }
   }
  .trading-right{
    margin-top: 50px;
    width: 58%;
    height: 350px;
   }
  }
 }
}





@media (max-width: 670px){
.trading{
    padding-top: 60px;
    height: 1680px;
   .trading-in{
     max-width: 300px;
   .trading-left{
      max-width: 300px;
      position: relative;
    p{
     padding-top: 20px;
     font-size: 13px;
     text-align: center;
     position: absolute;
     top: 1220px;
    }
    h2{
     text-align: center;
     padding-top: 0px;
     font-size: 23px;
     line-height: 40px;
    }
    .trading-btn{
     position: absolute;
     top: 1420px;
    }
   }
  .trading-right{
     margin-top: 50px;
     width: 100%;
     background:url(../images/img3.png) no-repeat 0 0;
     height: 1140px;
   }
  }
 }
}