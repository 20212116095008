*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;  
  }
  html, body {
    height: 100%;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: normal;
    min-width: 320px;
  }
  body{
    overflow-x: hidden;
  }
  a{
    outline: none;
    text-decoration: none;
  }
  img{
    outline: none;
    max-width: 100%;
    border:none;
  }
  ul{
    list-style: none;
  }
  .clearfix:before,
  .clearfix:after {
    content: " ";
    display: table;
  }
  .clearfix:after {
    clear: both;
  }
  
@media (max-width:840px){
  html, body{
    width: 100%;
    overflow-x: hidden;
  }
}