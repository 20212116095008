* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: normal;
  min-width: 320px;
}

body {
  overflow-x: hidden;
}

a {
  outline: none;
  text-decoration: none;
}

img {
  outline: none;
  max-width: 100%;
  border: none;
}

ul {
  list-style: none;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

@media (max-width: 840px) {
  html, body {
    width: 100%;
    overflow-x: hidden;
  }
}

.header .header-in .header-top .header-middle .header-left .header-btn, .trading .trading-in .trading-left .trading-btn, .results .results-in .results-btn, .wallets .wallets-in .wallets-content .wallets-btn, .exchanges .exchanges-in .exchanges-left .buy-btn,
.exchanges .exchanges-in .exchanges-right .buy-btn {
  width: 280px;
  height: 64px;
  display: block;
  text-align: center;
  padding-top: 20px;
  border-radius: 30px;
}

.header {
  background: #01050D;
  height: 820px;
}

.header .header-in {
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
}

.header .header-in .header-top {
  overflow: visible;
  width: 100%;
  float: left;
}

.header .header-in .header-top .mobil-button {
  width: 24px;
  display: block;
  border: none;
  background: none;
  cursor: pointer;
}

.header .header-in .header-top .mobil-button span {
  display: block;
  background-color: #ffffff;
  width: 24px;
  height: 2px;
  margin-bottom: 6px;
}

.header .header-in .header-top .mobil-button {
  display: none;
}

.header .header-in .header-top .header-middle {
  width: 100%;
  margin-top: 190px;
}

.header .header-in .header-top .header-middle .header-left {
  max-width: 670px;
  width: 100%;
  float: left;
  position: relative;
}

.header .header-in .header-top .header-middle .header-left:after {
  display: block;
  content: '';
  position: absolute;
  left: -450px;
  top: -400px;
  width: 1026px;
  height: 1026px;
  background-image: url(../images/firstScreenDecoration.svg);
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}

.header .header-in .header-top .header-middle .header-left * {
  position: relative;
  z-index: 2;
}

.header .header-in .header-top .header-middle .header-left h1 {
  color: #ffffff;
  font: 900 50px "Montserrat";
  line-height: 72px;
  position: relative;
}

.header .header-in .header-top .header-middle .header-left .header-btn {
  font: 700 18px "Montserrat";
  line-height: 26px;
  background: #2B63E3;
  padding-top: 17px;
  margin-top: 70px;
  color: #ffffff;
  width: 200px;
  height: 60px;
}

.header .header-in .header-top .header-middle .header-left .social-btn {
  margin-top: 167px;
}

.header .header-in .header-top .header-middle .header-left .social-btn li {
  display: inline-block;
  margin: 0 16px 0 0;
}

.header .header-in .header-top .header-middle .header-right {
  background-color: #2B63E3;
  max-width: 41%;
  height: 590px;
  width: 100%;
  float: right;
  position: absolute;
  right: 0;
  top: 199px;
}

.header .header-in .header-top .header-middle .header-right .header-content {
  max-width: 420px;
  width: 100%;
  margin: 73px 0 0 80px;
}

.header .header-in .header-top .header-middle .header-right .header-content .item {
  display: block;
  overflow: hidden;
  margin-bottom: 51px;
}

.header .header-in .header-top .header-middle .header-right .header-content .item span {
  color: #ffffff;
  font: 200 14px "Montserrat";
  opacity: 0.5;
  margin: 0 22px 0 0;
  position: relative;
  top: 8px;
  width: 20px;
  display: block;
  float: left;
}

.header .header-in .header-top .header-middle .header-right .header-content .item h2 {
  color: #ffffff;
  font: 900 36px "Montserrat";
}

.header .header-in .header-top .header-middle .header-right .header-content .item p {
  color: #ffffff;
  font: 200 14px "Montserrat";
  line-height: 26px;
  width: 296px;
  margin: 8px 0 0 45px;
}

.header .header-in .header-top .header-middle .header-right .fancybox {
  position: absolute;
  bottom: 77px;
  left: -377px;
  width: 415px;
  z-index: 2000;
}

.header .header-in .header-top .header-middle .header-right .fancybox:before {
  width: 101px;
  height: 101px;
  content: url(../images/icon12.png);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
}

.header .header-in .header-top .header-middle .header-right .fancybox img {
  max-width: 100%;
  box-shadow: 0px 40px 60px rgba(0, 0, 0, 0.4);
}

.header .header-in .logo {
  display: block;
  float: left;
  padding-top: 30px;
}

.header .header-in .top-menu {
  float: right;
  padding-top: 37px;
  display: block;
}

.header .header-in .top-menu li {
  display: inline-block;
  margin: 0 0 0 49px;
}

.header .header-in .top-menu li a {
  color: #ffffff;
  font: 200 16px "Montserrat";
  line-height: 26px;
}

.header .header-in .top-menu li a :last-of-type {
  margin: 0;
}

.trading {
  background: #01050D;
  height: 875px;
}

.trading .trading-in {
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
}

.trading .trading-in .trading-left {
  float: left;
  padding-top: 53px;
  max-width: 600px;
  width: 100%;
}

.trading .trading-in .trading-left h2 {
  font: 900 36px "Montserrat";
  line-height: 62px;
  color: #ffffff;
  padding-top: 100px;
}

.trading .trading-in .trading-left p {
  color: #ffffff;
  font: 200 14px "Montserrat";
  line-height: 26px;
  max-width: 470px;
  width: 100%;
  padding-top: 53px;
}

.trading .trading-in .trading-left .trading-btn {
  background-color: #2B63E3;
  margin-top: 70px;
  color: #ffffff;
  width: 200px;
  font: 600 18px "Montserrat";
}

.trading .trading-in .trading-right {
  margin-top: 157px;
  float: right;
  background: url(../images/img2.png) no-repeat 0 0;
  width: 625px;
  height: 597px;
}

.results {
  background-color: #2B63E3;
  width: 100%;
  height: 540px;
}

.results .results-in {
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
}

.results .results-in h2 {
  text-align: center;
  font: 900 50px "Montserrat";
  color: #ffffff;
  line-height: 62px;
  padding: 75px 0;
}

.results .results-in .net {
  font: 300 13px "Montserrat";
  color: #ffffff;
  float: left;
  margin: 16px 80px 0 0;
}

.results .results-in .net span {
  padding: 0 0 0 10px;
}

.results .results-in .results-btn {
  float: right;
  background-color: #ffffff;
  color: #2B63E3;
  font: bold 18px "Montserrat";
  width: 200px;
  height: 60px;
  margin-top: 8px;
}

.table-results-main {
  background-color: #01050D;
}

.table-results-main p {
  color: #ffffff;
  text-align: center;
  font: 300 14px "Montserrat";
  line-height: 26px;
  max-width: 1040px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  top: -123px;
}

.table-results-main .table-results {
  background-color: #ffffff;
  max-width: 1220px;
  width: 100%;
  margin: 0 auto;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  height: auto;
  padding-bottom: 10px;
  position: relative;
  top: -195px;
}

.table-results-main .table-results .table-results-header {
  width: 95%;
  margin: 0 auto;
  padding-top: 30px;
}

.table-results-main .table-results .table-results-header .table-results-tr {
  width: 100%;
  padding: 15px 0;
}

.table-results-main .table-results .table-results-header .table-results-tr .table-results-th {
  display: inline-block;
  width: 12.1%;
  font: normal 12px "Montserrat";
  color: #9AA5B8;
}

.table-results-main .table-results .table-results-header .table-results-tr .table-results-th:last-of-type {
  text-align: right;
}

.table-results-main .table-results .table-results-header .table-results-tr .table-results-th:nth-child(5), .table-results-main .table-results .table-results-header .table-results-tr .table-results-th:nth-child(6), .table-results-main .table-results .table-results-header .table-results-tr .table-results-th:nth-child(7) {
  text-align: right;
}

.table-results-main .table-results .table-results-header .table-results-tr .table-results-td {
  display: inline-block;
  width: 12.1%;
  font: normal 13px "Montserrat";
  color: #1F2229;
}

.table-results-main .table-results .table-results-header .table-results-tr .table-results-td:last-of-type {
  text-align: right;
}

.table-results-main .table-results .table-results-header .table-results-tr .table-results-td:nth-child(5), .table-results-main .table-results .table-results-header .table-results-tr .table-results-td:nth-child(6), .table-results-main .table-results .table-results-header .table-results-tr .table-results-td:nth-child(7) {
  text-align: right;
}

.table-results-main .table-results .table-results-header .table-results-tr .sell:before,
.table-results-main .table-results .table-results-header .table-results-tr .buy:before {
  display: block;
  width: 8px;
  height: 8px;
  background-color: #E55541;
  float: left;
  content: "";
  border-radius: 10px;
  margin: 5px 7px 0 0;
}

.table-results-main .table-results .table-results-header .table-results-tr .buy:before {
  background-color: #00D983;
}

.table-results-main .table-results .table-results-header .line-table {
  border: none;
  border-bottom: 1px solid #D8D8D8;
  width: 100%;
}

.fon {
  display: none;
}

.table-results-main .block {
  position: relative;
  z-index: 3;
}

.table-results-main .table {
  margin-top: 0px;
  padding: 51px 27px 27px;
  border-radius: 5px;
  background-color: #fff;
}

.table-results-main .table .tc {
  font-size: 13px;
  line-height: 1.1;
  color: #1f2229;
}

.table-results-main .table .tc:nth-child(1) {
  width: 5%;
}

.table-results-main .table .tc:nth-child(2) {
  width: 7%;
}

.table-results-main .table .tc:nth-child(3) {
  width: 7%;
}

.table-results-main .table .tc:nth-child(4) {
  width: 7%;
}

.table-results-main .table .tc:nth-child(5) {
  width: 10%;
}

.table-results-main .table .tc:nth-child(6) {
  width: 7%;
}

.table-results-main .table .tc:nth-child(7) {
  width: 10%;
}

.table-results-main .table .tc:nth-child(8) {
  width: 8%;
}

.table-results-main .table .tc:nth-child(9) {
  width: 10%;
}

.table-results-main .table .tc:nth-child(10) {
  width: 13%;
  text-align: right;
}

.table-results-main .thead {
  display: flex;
  justify-content: space-between;
  padding: 0 6px;
  padding-bottom: 18px;
  border-bottom: 1px solid #e8e9ea;
}

.table-results-main .thead .tc {
  font-size: 12px;
  line-height: 16px;
  color: #9aa5b8;
}

.table-results-main .tbody .tc:last-child {
  text-align: right;
}

.table-results-main .tr {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 6px;
  border-bottom: 1px solid #e8e9ea;
}

.table-results-main .loadmore {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.table-results-main .load {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1px;
  color: #9aa5b8;
  text-decoration: none;
}

.table-results-main .signal {
  padding-left: 20px;
  position: relative;
}

.table-results-main .signal.green:after {
  background-color: #00D983;
}

.table-results-main .signal:after {
  z-index: 1;
  content: '';
  display: block;
  position: absolute;
  left: 0;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: #e55541;
  top: 50%;
  margin-top: -4px;
}

.loader-block {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.lds-ring-loader {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  opacity: 0.5;
}

.lds-ring-loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 6px;
  border: 3px solid #2B63E3;
  border-radius: 50%;
  animation: lds-ring-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #2B63E3 transparent transparent transparent;
}

.lds-ring-loader div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring-loader div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring-loader div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.specification {
  background-color: #01050D;
  overflow: hidden;
  height: auto;
}

.specification .specification-in {
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
}

.specification .specification-in h2 {
  color: #ffffff;
  font: 900 50px "Montserrat";
  text-align: center;
  padding: 166px 0 70px;
}

.table-specification {
  float: left;
  max-width: 720px;
  width: 100%;
}

.table-specification .table-specification-header {
  border-bottom: 1px solid #005BE9;
  padding-bottom: 15px;
}

.table-specification .table-specification-header .table-specification-td {
  display: table-cell;
  color: #9AA5B8;
  font: 300 16px "Montserrat";
  line-height: 24px;
  width: 136px;
  padding: 0 0 0 17px;
}

.table-specification .table-specification-hr {
  border-bottom: 1px solid #005BE9;
  padding: 7px 0;
}

.table-specification .table-specification-hr:nth-child(2n) {
  background-color: #0F1929;
}

.table-specification .table-specification-hr .table-specification-td {
  display: table-cell;
  width: 136px;
  padding: 0 0 0 21px;
}

.table-specification .table-specification-hr .table-specification-td b {
  color: #ffffff;
  font: 600 16px "Montserrat";
}

.table-specification2 {
  float: right;
  max-width: 450px;
  width: 100%;
  margin-top: 39px;
}

.table-specification2 .table-specification-hr {
  border-top: 1px solid #005BE9;
  padding: 0px 0 1px 0;
  display: flex;
}

.table-specification2 .table-specification-hr:nth-child(2n+1) {
  background-color: #0F1929;
}

.table-specification2 .table-specification-hr .table-specification-td {
  width: 245px;
  padding: 17.6px 25px;
}

.table-specification2 .table-specification-hr .table-specification-td.table-specification-td__left {
  flex-shrink: 0;
}

.table-specification2 .table-specification-hr .table-specification-td b {
  color: #ffffff;
  font: 600 16px "Montserrat";
}

.table-specification2 .table-specification-hr .table-specification-td:last-of-type {
  text-align: right;
}

.table-specification2 .table-specification-hr:last-of-type {
  border-bottom: 1px solid #005BE9;
  padding: 3px 0 3px 0;
}

.our-team {
  background-color: #01050D;
}

.our-team .our-team-in {
  max-width: 950px;
  width: 100%;
  margin: 0 auto;
  padding-top: 100px;
}

.our-team .our-team-in h2 {
  font: 900 48px "Montserrat";
  text-align: center;
  color: #ffffff;
  padding-bottom: 85px;
}

.our-team * {
  outline: none;
}

.slider .item {
  position: relative;
  padding-top: 50px;
}

.slider .item img {
  display: block;
  margin: 0 auto;
}

.slider .item h3 {
  font: 700 24px "Montserrat";
  color: #ffffff;
  line-height: 34px;
  text-align: center;
  padding: 20px 0;
  margin-top: 240px;
}

.slider .item span {
  color: #ffffff;
  font: normal 16px "Montserrat";
  text-align: center;
  padding-bottom: 20px;
}

.slider .item p {
  text-align: center;
  font: 300 16px "Montserrat";
  color: #ffffff;
  line-height: 24px;
}

.slider .slick-list h3,
.slider .slick-list span,
.slider .slick-list p {
  display: none;
}

.slider .slick-list .item:before {
  content: "";
  background-color: rgba(0, 91, 233, 0.5);
  position: absolute;
  width: 143px;
  height: 143px;
  display: block;
  border-radius: 150px;
  left: 87px;
  top: 50px;
}

.slider .slick-list .item img {
  width: 143px;
  height: 143px;
}

.slider .slick-list .item .social-btn {
  display: none;
}

.slider .slick-list .slick-center {
  padding-top: 0px;
}

.slider .slick-list .slick-center:before {
  display: none !important;
}

.slider .slick-list .slick-center .social-btn {
  display: block;
  position: absolute;
  left: 40px;
  top: 0px;
}

.slider .slick-list .slick-center h3,
.slider .slick-list .slick-center span,
.slider .slick-list .slick-center p {
  display: block;
}

.slider .slick-center img {
  width: 204px !important;
  height: 204px !important;
  display: block;
  position: absolute;
  z-index: -1000;
  left: 15%;
}

.slider .social-btn img {
  width: 26px !important;
  height: 26px !important;
  margin-top: 30px;
}

.slider .next {
  right: 0;
  position: absolute;
  cursor: pointer;
  top: 170px;
}

.slider .prev {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 170px;
  z-index: 1000;
}

.slider .slick-dots {
  width: 130px;
  margin: 50px auto 0;
}

.slider .slick-dots li {
  display: inline-block;
  margin: 0 10px 0 0;
}

.slider .slick-dots li button {
  border: 2px solid #ffffff;
  width: 18px;
  height: 18px;
  text-indent: -999999px;
  border-radius: 50px;
  background-color: #1A1A1A;
  cursor: pointer;
  outline: none;
}

.slider .slick-dots li:last-of-type {
  margin: 0;
}

.slider .slick-dots .slick-active button {
  background-color: #2B63E3;
  border: 2px solid #2B63E3;
}

.development {
  background-color: #01050D;
  padding-top: 107px;
  position: relative;
  overflow: hidden;
  padding-bottom: 50px;
}

.development .development-left {
  background-color: #2B63E3;
  max-width: 35%;
  position: absolute;
  top: -80px;
  left: 0;
  width: 100%;
  z-index: 2;
}

.development .development-left:after {
  display: block;
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: -1000%;
  z-index: 1;
  background-color: #2B63E3;
}

.development .development-left h2 {
  font: 900 48px "Montserrat";
  line-height: 62px;
  color: #ffffff;
  width: 363px;
  padding: 109px 0 90px;
  margin: 0;
  position: relative;
  z-index: 2;
}

.development .development-in {
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1000;
}

.development .development-in:after {
  display: block;
  position: absolute;
  height: 1px;
  width: 100%;
  left: 0;
  top: 0;
  content: '';
  z-index: 3;
  background-color: #fff;
}

.development .development-in .table-development {
  max-width: 730px;
  width: 100%;
  float: right;
  padding-top: 50px;
}

.development .development-in .table-development .table-development-tr {
  min-height: 48px;
  overflow: hidden;
  width: 100%;
  margin-bottom: 27px;
  display: flex;
}

.development .development-in .table-development .table-development-tr .table-development-td {
  width: 275px;
  display: inline-block;
  color: #ffffff;
}

.development .development-in .table-development .table-development-tr .table-development-td.table-development-td__month {
  width: 41%;
}

.development .development-in .table-development .table-development-tr .table-development-td.table-development-td__text {
  width: 40%;
}

.development .development-in .table-development .table-development-tr .table-development-td.table-development-td__year {
  width: 27%;
}

.development .development-in .table-development .table-development-tr .table-development-td b {
  font: 600 18px "Montserrat";
}

.development .development-in .table-development .table-development-tr .table-development-td p {
  font: 300 14px "Montserrat";
  line-height: 24px;
}

.development .development-in .table-development .table-development-tr .table-development-td .date {
  text-align: right;
  width: 100%;
  font-weight: 600;
  display: block;
}

.wallets {
  background-color: #01050D;
  padding-bottom: 100px;
}

.wallets .wallets-in {
  max-width: 1030px;
  width: 100%;
  margin: 0 auto;
}

.wallets .wallets-in h2 {
  font: 900 48px "Montserrat";
  line-height: 62px;
  color: #ffffff;
  padding: 131px 0 70px;
  text-align: center;
}

.wallets .wallets-in .wallets-content {
  display: table-cell;
  width: 50%;
}

.wallets .wallets-in .wallets-content .wallets-btn {
  float: left;
  border: 1px solid #ffffff;
  font: bold 14px "Montserrat";
  padding-top: 20px;
  color: #ffffff;
  width: 200px;
  height: 60px;
}

.wallets .wallets-in .wallets-content .wallets-btn:before {
  content: url(../images/icon.svg);
  width: 23px;
  height: 23px;
  display: block;
  float: left;
  padding: 0 10px 0 0;
  position: relative;
  left: 31px;
  top: -1px;
}

.wallets .wallets-in .wallets-content .mac:before {
  content: url(../images/icon2.svg);
  width: 23px;
  height: 23px;
  display: block;
  float: left;
  padding: 0 10px 0 0;
  position: relative;
  top: -4px;
  left: 57px;
}

.wallets .wallets-in .wallets-content .linux:before {
  content: url(../images/icon3.svg);
  width: 23px;
  height: 23px;
  display: block;
  float: left;
  padding: 0 10px 0 0;
  position: relative;
  top: -2px;
  left: 57px;
}

.exchanges {
  background-color: #01050D;
  padding-bottom: 120px;
}

.exchanges .exchanges-in {
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
}

.exchanges .exchanges-in h2 {
  font: 900 48px "Montserrat";
  color: #ffffff;
  line-height: 62px;
  text-align: center;
  padding: 57px 0 57px;
}

.exchanges .exchanges-in .exchanges-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0 60px;
}

.exchanges .exchanges-in .exchanges-left {
  margin-right: 20px;
}

.exchanges .exchanges-in .exchanges-left,
.exchanges .exchanges-in .exchanges-right {
  float: left;
  width: 100%;
  padding-top: 67px;
  display: flex;
  border-radius: 5px;
  padding-bottom: 63px;
  align-items: center;
  flex-direction: column;
}

.exchanges .exchanges-in .exchanges-left .buy-btn,
.exchanges .exchanges-in .exchanges-left .buy-btn,
.exchanges .exchanges-in .exchanges-right .buy-btn,
.exchanges .exchanges-in .exchanges-right .buy-btn {
  font: bold 18px "Montserrat";
  background-color: #ffffff;
  color: #2B63E3;
  margin: 45px auto 0;
  width: 200px;
  height: 60px;
}

.exchanges .exchanges-in .exchanges-right {
  float: right;
}

.footer {
  background-color: #ffffff;
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
}

.footer h2 {
  font: 900 48px "Montserrat";
  color: #1F2229;
  line-height: 62px;
  text-align: center;
  padding: 75px 0;
  padding-bottom: 65px;
}

.footer .footer-social {
  max-width: 440px;
  width: 100%;
  margin: 0 auto;
}

.footer .footer-social li {
  display: inline-block;
  margin: 0 50px 0 0;
}

.footer .footer-social li:last-of-type {
  margin: 0;
}

.footer .footer-bottom {
  overflow: hidden;
  padding: 37px 0;
}

.footer .footer-bottom a {
  font: bold 20px "Montserrat";
  font-family: 'Times New Roman', Times, serif;
  color: #9AA5B8;
  float: left;
}

.footer .footer-bottom .footer-menu {
  max-width: 385px;
  width: 100%;
  float: left;
  margin: 3px 0 0 390px;
}

.footer .footer-bottom .footer-menu li {
  display: inline-block;
  margin: 0 25px 0 0;
}

.footer .footer-bottom .footer-menu li:last-of-type {
  margin: 0;
}

.footer .footer-bottom .footer-menu li a {
  color: #9AA5B8;
  font: 600 16px "Montserrat";
  line-height: 22px;
}

.footer .footer-bottom .copyright {
  display: block;
  float: right;
  font: 300 16px "Montserrat";
  color: #1A1A1A;
  padding-top: 3px;
}

@media (max-width: 1300px) {
  .header .header-in {
    max-width: 1000px;
  }
  .header .header-in .header-top .header-middle .header-left {
    max-width: 500px;
  }
  .header .header-in .header-top .header-middle .header-left h1 {
    font-size: 40px;
  }
  .header .header-in .header-top .header-middle .header-right .fancybox {
    width: 400px;
    top: 218px;
    left: -369px;
  }
  .header .header-in .header-top .header-middle .header-right .fancybox:before {
    top: 56px;
    left: 146px;
  }
  .header .header-in .header-top .header-middle .header-right .header-content {
    max-width: 300px;
    margin: 50px 0 0 50px;
  }
  .header .header-in .header-top .header-middle .header-right .header-content .item h2 {
    font-size: 27px;
  }
}

@media (max-width: 1030px) {
  .header .header-in {
    max-width: 800px;
  }
  .header .header-in .header-top .header-middle .header-left {
    max-width: 325px;
  }
  .header .header-in .header-top .header-middle .header-left h1 {
    font-size: 25px;
    line-height: 43px;
  }
  .header .header-in .header-top .header-middle .header-left .social-btn {
    margin-top: 250px;
  }
  .header .header-in .header-top .header-middle .header-right {
    max-width: 45%;
  }
  .header .header-in .header-top .header-middle .header-right .fancybox {
    width: 300px;
    left: -300px;
    top: 300px;
  }
  .header .header-in .header-top .header-middle .header-right .fancybox:before {
    top: 80px;
    left: 50%;
  }
  .header .header-in .header-top .header-middle .header-right .header-content {
    margin: 50px 0 0 27px;
    max-width: 270px;
  }
  .header .header-in .header-top .header-middle .header-right .header-content .item p {
    width: 180px;
    line-height: 20px;
    margin: 16px 0 0 30px;
  }
  .header .header-in .header-top .header-middle .header-right .header-content .item h2 {
    font-size: 20px;
  }
  .header .header-in .header-top .header-middle .header-right .header-content .item span {
    margin: -3px 10px 0 0;
  }
  .header .header-in .top-menu li {
    margin: 0 0 0 10px;
  }
}

@media (max-width: 830px) {
  .header .header-in {
    max-width: 600px;
  }
  .header .header-in .top-menu li a {
    font-size: 13px;
  }
}

@media (max-width: 670px) {
  .header {
    height: 1250px;
  }
  .header .header-in {
    max-width: 300px;
  }
  .header .header-in .header-top {
    position: relative;
    height: 1250px;
  }
  .header .header-in .header-top .logo {
    position: relative;
    z-index: 3;
  }
  .header .header-in .header-top .mobil-button {
    position: absolute;
    right: 7px;
    top: 40px;
    display: block;
    outline: none;
    z-index: 3;
  }
  .header .header-in .header-top .header-middle .header-left h1 {
    text-align: center;
  }
  .header .header-in .header-top .header-middle .header-left .header-btn {
    float: none;
    margin: 50px auto 0;
  }
  .header .header-in .header-top .header-middle .header-left .social-btn {
    margin: 810px auto 0;
    max-width: 265px;
  }
  .header .header-in .header-top .header-middle .header-left .social-btn li:last-of-type {
    margin: 0;
  }
  .header .header-in .header-top .header-middle .header-right {
    max-width: 100%;
    top: 600px;
    height: 550px;
  }
  .header .header-in .header-top .header-middle .header-right .fancybox {
    left: 0;
    top: -147px;
  }
  .header .header-in .header-top .header-middle .header-right .header-content {
    margin: 70px 0 0 15px;
  }
  .header .header-in .header-top .header-middle .header-right .header-content .item p {
    width: 240px;
    font-size: 15px;
  }
  .header .header-in .top-menu {
    display: none;
    background: #ffffff;
    margin: 6px;
    width: 200px;
    box-shadow: 0 0 10px #ffffff;
    padding: 20px 0;
    position: absolute;
    left: 87px;
    top: 62px;
    z-index: 1000;
  }
  .header .header-in .top-menu li {
    display: block;
    width: 100%;
    overflow: hidden;
    margin: 0 0 10px 0;
  }
  .header .header-in .top-menu li a {
    font-size: 15px;
    color: #1A1A1A;
    text-align: center;
    display: block;
    width: 100%;
    float: none;
  }
}

@media (max-width: 1380px) {
  .trading .trading-in {
    max-width: 1200px;
  }
  .trading .trading-in .trading-left {
    max-width: 505px;
  }
  .trading .trading-in .trading-left h2 {
    line-height: 50px;
    font-size: 35px;
  }
}

@media (max-width: 1240px) {
  .trading .trading-in {
    max-width: 1000px;
  }
  .trading .trading-in .trading-left {
    max-width: 405px;
  }
  .trading .trading-in .trading-left h2 {
    line-height: 50px;
    font-size: 30px;
  }
  .trading .trading-in .trading-right {
    width: 54%;
    background-size: 100%;
  }
}

@media (max-width: 1060px) {
  .trading {
    height: 700px;
  }
  .trading .trading-in {
    max-width: 800px;
  }
  .trading .trading-in .trading-left {
    max-width: 350px;
  }
  .trading .trading-in .trading-left h2 {
    line-height: 40px;
    font-size: 25px;
    padding-top: 0;
  }
  .trading .trading-in .trading-left p {
    padding-top: 40px;
  }
  .trading .trading-in .trading-left .trading-btn {
    margin-top: 40px;
  }
  .trading .trading-in .trading-right {
    width: 50%;
    background-size: 100%;
    padding-top: 70px;
  }
}

@media (max-width: 830px) {
  .trading {
    height: 550px;
  }
  .trading .trading-in {
    max-width: 600px;
  }
  .trading .trading-in .trading-left {
    max-width: 250px;
  }
  .trading .trading-in .trading-left p {
    padding-top: 20px;
    font-size: 12px;
  }
  .trading .trading-in .trading-left h2 {
    line-height: 30px;
    font-size: 19px;
    padding-top: 0;
  }
  .trading .trading-in .trading-right {
    margin-top: 50px;
    width: 58%;
    height: 350px;
  }
}

@media (max-width: 670px) {
  .trading {
    padding-top: 60px;
    height: 1680px;
  }
  .trading .trading-in {
    max-width: 300px;
  }
  .trading .trading-in .trading-left {
    max-width: 300px;
    position: relative;
  }
  .trading .trading-in .trading-left p {
    padding-top: 20px;
    font-size: 13px;
    text-align: center;
    position: absolute;
    top: 1220px;
  }
  .trading .trading-in .trading-left h2 {
    text-align: center;
    padding-top: 0px;
    font-size: 23px;
    line-height: 40px;
  }
  .trading .trading-in .trading-left .trading-btn {
    position: absolute;
    top: 1420px;
  }
  .trading .trading-in .trading-right {
    margin-top: 50px;
    width: 100%;
    background: url(../images/img3.png) no-repeat 0 0;
    height: 1140px;
  }
}

@media (max-width: 1290px) {
  .results .results-in {
    max-width: 1200px;
  }
}

@media (max-width: 1230px) {
  .results .results-in {
    max-width: 1000px;
  }
}

@media (max-width: 1060px) {
  .results .results-in {
    max-width: 800px;
  }
  .results .results-in .net {
    margin: 20px 40px 0 0;
  }
}

@media (max-width: 820px) {
  .results .results-in {
    max-width: 600px;
  }
  .results .results-in .net {
    margin: 20px 70px 0 0;
  }
  .results .results-in .net:last-of-type {
    margin: 20px 0 0;
  }
  .results .results-in h2 {
    font-size: 30px;
  }
  .results .results-in .results-btn {
    float: none;
    margin: 100px auto 0;
    overflow: hidden;
  }
}

@media (max-width: 820px) {
  .results .results-in {
    max-width: 300px;
  }
  .results .results-in .net {
    margin: 20px auto 0;
    float: none;
  }
  .results .results-in .net span {
    padding: 0 0 0 30px;
  }
  .results .results-in .net:last-of-type {
    margin: 20px 0 0;
    padding: 0 0 0 10px;
  }
  .results .results-in .net:nth-child(2) {
    padding: 0 0 0 62px;
  }
  .results .results-in h2 {
    font-size: 27px;
    padding: 50px 0 0 0;
  }
  .results .results-in .results-btn {
    margin: 50px auto 0;
  }
}

@media (max-width: 770px) {
  .results {
    height: 320px;
  }
}

@media (max-width: 1260px) {
  .table-results-main .table-results {
    max-width: 1000px;
    height: 630px;
  }
}

@media (max-width: 1060px) {
  .table-results-main p {
    padding: 0 2%;
    font-size: 13px;
  }
  .table-results-main .table-results {
    max-width: 900px;
    height: 630px;
  }
  .table-results-main .table-results .table-results-header .table-results-tr .table-results-th {
    width: 11.9%;
  }
  .table-results-main .table-results .table-results-header .table-results-tr .table-results-td {
    width: 11.8%;
  }
}

@media (max-width: 930px) {
  .table-results-main .table-results {
    max-width: 750px;
  }
}

@media (max-width: 770px) {
  .table-results-main p {
    font-size: 12px;
    width: 300px;
    padding-top: 150px;
  }
  .table-results-main .table-results {
    display: none;
  }
  .table-mobil {
    display: block;
    background-color: #ffffff;
    width: 300px;
    margin: 0 auto;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 3;
  }
  .table-mobil:before {
    content: "";
    display: block;
    background-color: red;
    width: 100%;
  }
  .table-mobil .table-mobil-tr {
    overflow: hidden;
  }
  .table-mobil .table-mobil-tr .table-mobil-td-header {
    float: left;
    padding: 16px 0 13px 0px;
    width: 35%;
    color: #9AA5B8;
    font-size: 14px;
  }
  .table-mobil .table-mobil-tr .table-mobil-td {
    float: left;
    width: 65%;
    font-size: 14px;
    color: #1F2229;
    margin-top: 16px;
    padding: 0 0 0 35px;
    text-align: right;
  }
  .table-mobil .table-mobil-tr .sell:before {
    display: block;
    width: 8px;
    height: 8px;
    background-color: #E55541;
    float: left;
    content: "";
    -webkit-border-radius: 10px;
    border-radius: 10px;
    margin: 5px 7px 0 0;
  }
  .line-table {
    border: none;
    border-bottom: 1px solid #D8D8D8;
    width: 100%;
    margin: 10px auto;
  }
  .load-more {
    text-transform: uppercase;
    color: #9AA5B8;
    font-weight: bold;
    text-align: center;
    padding: 30px 0;
    display: block;
    cursor: pointer;
  }
  .fon {
    display: block;
    position: relative;
  }
  .fon:before {
    content: "";
    background-color: #2B63E3;
    display: block;
    height: 400px;
    position: absolute;
    width: 100%;
  }
}

@media (max-width: 1360px) {
  .specification .specification-in {
    max-width: 1000px;
  }
  .specification .specification-in .table-specification {
    max-width: 550px;
  }
  .specification .specification-in .table-specification .table-specification-header .table-specification-td {
    font-size: 13px;
  }
  .specification .specification-in .table-specification .table-specification-hr {
    padding: 9px 0 8px;
  }
  .specification .specification-in .table-specification .table-specification-hr .table-specification-td b {
    font-size: 13px;
    display: block;
    width: 0;
  }
  .specification .specification-in .table-specification2 {
    max-width: 420px;
  }
  .specification .specification-in .table-specification2 .table-specification-hr {
    padding: 2px 0 3px 0;
  }
  .specification .specification-in .table-specification2 .table-specification-hr .table-specification-td b {
    font-size: 13px;
  }
}

@media (max-width: 1090px) {
  .specification .specification-in {
    max-width: 850px;
  }
  .specification .specification-in .table-specification {
    max-width: 450px;
  }
  .specification .specification-in .table-specification .table-specification-header .table-specification-td {
    font-size: 11px;
    padding: 0 0 0 10px;
  }
  .specification .specification-in .table-specification .table-specification-hr {
    padding: 9px 0;
  }
  .specification .specification-in .table-specification .table-specification-hr .table-specification-td b {
    font-size: 11px;
  }
  .specification .specification-in .table-specification2 {
    max-width: 380px;
  }
  .specification .specification-in .table-specification2 .table-specification-hr {
    padding: 3px 0 5px 0;
  }
  .specification .specification-in .table-specification2 .table-specification-hr .table-specification-td b {
    font-size: 11px;
  }
}

@media (max-width: 880px) {
  .specification .specification-in {
    max-width: 700px;
  }
  .specification .specification-in .table-specification {
    max-width: 400px;
  }
  .specification .specification-in .table-specification .table-specification-header .table-specification-td {
    font-size: 11px;
    padding: 0 0 0 5px;
  }
  .specification .specification-in .table-specification .table-specification-hr {
    padding: 9px 0;
  }
  .specification .specification-in .table-specification .table-specification-hr .table-specification-td {
    padding: 0 0 0 8px;
  }
  .specification .specification-in .table-specification .table-specification-hr .table-specification-td b {
    font-size: 11px;
    display: block;
    width: 0;
  }
  .specification .specification-in .table-specification2 {
    max-width: 275px;
  }
  .specification .specification-in .table-specification2 .table-specification-hr {
    padding: 4px 0 5px 0;
  }
  .specification .specification-in .table-specification2 .table-specification-hr .table-specification-td {
    padding: 5.45% 5px;
  }
  .specification .specification-in .table-specification2 .table-specification-hr .table-specification-td b {
    font-size: 11px;
  }
}

@media (max-width: 730px) {
  .specification {
    height: auto;
  }
  .specification .specification-in {
    max-width: 300px;
  }
  .specification .specification-in h2 {
    font-size: 40px;
    padding: 10px 0 30px;
  }
  .specification .specification-in .table-specification {
    max-width: 300px;
  }
  .specification .specification-in .table-specification .table-specification-header .table-specification-td {
    font-size: 10px;
    padding: 0;
    line-height: 15px;
  }
  .specification .specification-in .table-specification .table-specification-hr {
    padding: 9px 0;
  }
  .specification .specification-in .table-specification .table-specification-hr .table-specification-td {
    padding: 0;
  }
  .specification .specification-in .table-specification .table-specification-hr .table-specification-td b {
    font-size: 10px;
  }
  .specification .specification-in .table-specification2 {
    max-width: 300px;
  }
  .specification .specification-in .table-specification2 .table-specification-hr {
    padding: 4px 0 5px 0;
  }
  .specification .specification-in .table-specification2 .table-specification-hr .table-specification-td {
    padding: 5.45% 5px;
  }
  .specification .specification-in .table-specification2 .table-specification-hr .table-specification-td b {
    font-size: 10px;
  }
}

@media (max-width: 1230px) {
  .our-team .our-team-in {
    max-width: 700px;
  }
  .our-team .our-team-in h2 {
    font-size: 38px;
  }
  .our-team .slider .item p {
    font-size: 15px;
  }
  .our-team .slider .slick-center > img {
    width: 170px !important;
    height: 170px !important;
  }
  .our-team .slider .slick-list .slick-active:before {
    left: 45px;
    top: 1px;
  }
}

@media (max-width: 720px) {
  .our-team .our-team-in {
    max-width: 300px;
  }
  .our-team .our-team-in h2 {
    font-size: 30px;
  }
  .our-team .slider .item p {
    font-size: 12px;
  }
  .our-team .slider .slick-center > img {
    width: 170px !important;
    height: 170px !important;
    left: 22%;
  }
  .our-team .slider .slick-list .slick-active:before {
    left: 45px;
    top: 1px;
  }
}

@media (max-width: 1580px) {
  .development .development-in .table-development {
    max-width: 700px;
  }
  .development .development-in .table-development .table-development-tr .table-development-td {
    width: 190px;
  }
  .development .development-left {
    max-width: 40%;
  }
}

@media (max-width: 1180px) {
  .development .development-in {
    max-width: 980px;
  }
  .development .development-in .table-development {
    max-width: 600px;
  }
  .development .development-left {
    max-width: 36%;
  }
  .development .development-left h2 {
    margin: 0 0 0 20px;
    font-size: 40px;
  }
}

@media (max-width: 1080px) {
  .development .development-in {
    max-width: 800px;
  }
  .development .development-in .table-development {
    max-width: 500px;
  }
  .development .development-in .table-development .table-development-tr .table-development-td p {
    font-size: 12px;
  }
  .development .development-left {
    max-width: 36%;
  }
  .development .development-left h2 {
    margin: 0 0 0 20px;
    font-size: 30px;
    width: 270px;
  }
}

@media (max-width: 840px) {
  .development .development-in {
    max-width: 600px;
  }
  .development .development-in .table-development {
    max-width: 350px;
  }
  .development .development-in .table-development .table-development-tr .table-development-td p {
    font-size: 12px;
    line-height: 20px;
  }
  .development .development-in .table-development .table-development-tr .table-development-td b {
    font-size: 15px;
  }
  .development .development-left {
    max-width: 36%;
  }
  .development .development-left h2 {
    margin: 0 0 0 20px;
    font-size: 25px;
    width: 270px;
    line-height: 40px;
  }
}

@media (max-width: 680px) {
  .development {
    padding: 0px 0 0;
  }
  .development .development-in {
    max-width: 300px;
    margin-top: -240px;
    padding-top: 240px;
  }
  .development .development-in .table-development {
    max-width: 350px;
  }
  .development .development-in .table-development .table-development-tr .table-development-td p {
    font-size: 12px;
    line-height: 20px;
    width: 180px;
  }
  .development .development-in .table-development .table-development-tr .table-development-td b {
    font-size: 15px;
    width: 75px;
    display: block;
  }
  .development .development-left {
    max-width: 300px;
    float: none;
    position: relative;
    top: 0;
    margin: 0 auto;
  }
  .development .development-left h2 {
    font-size: 31px;
    width: 270px;
    line-height: 49px;
    text-align: center;
    padding: 70px 0 70px;
    margin: 0 auto;
  }
}

@media (max-width: 1130px) {
  .wallets .wallets-in {
    max-width: 900px;
  }
}

@media (max-width: 990px) {
  .wallets .wallets-in {
    max-width: 700px;
  }
}

@media (max-width: 750px) {
  .wallets {
    padding-bottom: 30px;
  }
  .wallets .wallets-in {
    max-width: 300px;
  }
  .wallets .wallets-in h2 {
    font-size: 30px;
    padding: 150px 0 30px;
  }
  .wallets .wallets-in .wallets-content {
    display: block;
    width: 100%;
  }
  .wallets .wallets-in .wallets-content a {
    float: none !important;
    display: block !important;
    margin: 0 auto 25px;
  }
}

@media (max-width: 990px) {
  .exchanges .exchanges-in {
    max-width: 700px;
  }
}

@media (max-width: 750px) {
  .exchanges .exchanges-in {
    max-width: 600px;
  }
  .exchanges .exchanges-in .exchanges-right a {
    margin-top: 8px;
    display: block;
  }
}

@media (max-width: 750px) {
  .exchanges {
    padding-bottom: 90px;
  }
  .exchanges .exchanges-in {
    max-width: 300px;
  }
  .exchanges .exchanges-in h2 {
    font-size: 30px;
    padding: 50px 0 50px;
  }
  .exchanges .exchanges-in .exchanges-right,
  .exchanges .exchanges-in .exchanges-left {
    float: none;
    margin: 0 auto;
  }
  .exchanges .exchanges-in .exchanges-right a,
  .exchanges .exchanges-in .exchanges-left a {
    margin-top: 8px;
    display: block;
  }
  .exchanges .exchanges-in .exchanges-right .buy-btn,
  .exchanges .exchanges-in .exchanges-left .buy-btn {
    margin: 25px auto 0;
    float: none;
  }
  .exchanges .exchanges-in .exchanges-right {
    float: none;
    overflow: hidden;
    margin: 50px auto 0;
  }
}

@media (max-width: 1290px) {
  .footer {
    max-width: 1200px;
  }
}

@media (max-width: 1230px) {
  .footer {
    max-width: 1000px;
  }
  .footer .footer-bottom .footer-menu {
    max-width: 385px;
    width: 100%;
    float: left;
    margin: 3px 0 0 270px;
  }
}

@media (max-width: 1060px) {
  .footer {
    max-width: 800px;
  }
  .footer .footer-bottom .footer-menu {
    margin: 3px 0 0 150px;
  }
}

@media (max-width: 820px) {
  .footer {
    max-width: 320px;
  }
  .footer h2 {
    font-size: 30px;
    padding: 50px 0 30px 0;
  }
  .footer .footer-bottom .footer-menu {
    margin: 3px 0 0 0;
  }
  .footer .footer-bottom .footer-menu li {
    display: block;
    margin: 0;
  }
  .footer .footer-bottom a {
    float: none;
    text-align: center;
    width: 100%;
    display: block;
    padding-bottom: 30px;
  }
  .footer .footer-bottom .copyright {
    float: none;
    text-align: center;
  }
  .footer .footer-social li {
    display: inline-block;
    margin: 0 20px 0 0;
  }
}
